* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
font-family: 'Inconsolata', monospace;
color: #FFFFFF;
background: #0F0823;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #2A363B; 
}

::-webkit-scrollbar-thumb {
  background: #82DFF6; 
}

::-webkit-scrollbar-thumb:hover {
  background: #F784F5; 
}

.main-container {
  display: flex;
  align-items: center;
  height: 100vh;
  background-image: url("../src/assets/home-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.frontIcon {
  position: absolute;
  width: 90px;
  z-index: 3;
  animation: homeIcon 2s ease-in-out infinite;
  animation-direction: alternate-reverse;
}

.code {
  top: 20%;
  left: 30%;
}

.cloud {
  top: 40%;
  right: 30%;
}

.network {
  bottom: 20%;
  left: 37%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-info {
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  padding: 10px 5px;
  text-align: center;
  background: #0F0823;
}

.intoduction-words {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 20px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 30%;
  background: rgba(35,29,54,0.9);
  margin: 10% 0% 0% 9%;
  z-index: 10;
}

.first {
  font-size: 30px;
}

.second {
  font-size: 35px;
  margin: 17px 0px 15px 17px;
  background: linear-gradient(to right, #F784F5 0%, #82DFF6 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  border: 3px solid #F784F5;
  padding: 2px 20px;
  box-shadow: 0 0 10px #82DFF6;
}

.third {
  font-size: 30px;
  margin-left: 45px;
}

.forth {
  font-size: 20px;
  color: #BDA5AD;
  text-align: start;
  margin: 50px 0px 50px 35px;
}

.projects-link {
  text-decoration: none;
  font-weight: bolder;
  color: #FFFEFF;
}

.projects-link:hover {
  color: #82DFF6;
}

.btn-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.margin-top {
  margin-top: 50px;
}

.resume-link {
  text-decoration: none;
  color: #0F0823;
}

.cta {
  font-size: 18px;
  font-weight: 700;
  padding: 10px 30px;
  cursor: pointer;
  text-decoration: none;
  color: #0F0823;
  background: linear-gradient(to left, #F784F5 0%, #82DFF6 100%);
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  align-self: flex-end;
}

.cta::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #82DFF6;
  transform: translateX(-100%);
  transition: all .3s;
  z-index: -1;
}

.cta:hover::before {
  transform: translateX(0);
}

.download-icon {
  margin-left: 7px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about {
  margin: 0px 200px 50px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  padding-top: 140px;
}

.my-photo {
  width: 350px;
  height: 550px;
  border-radius: 5px;
  -webkit-box-shadow: -20px 20px 5px 0px rgba(130,223,246,1);
  -moz-box-shadow: -20px 20px 5px 0px rgba(130,223,246,1);
  box-shadow: -20px 20px 5px 0px rgba(130,223,246,1);
  z-index: -20;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.my-info, .my-facts {
  font-size: 21px;
  padding: 10px 0px;
}

.flex {
  display: flex;
  align-items: center;
}

.right-arrow {
  margin-right: 15px;
  color: #82DFF6;
  font-size: 27px;
}

.aboutMe-heading {
  font-size: 45px;
  margin-bottom: 40px;
}

.myName {
  background: linear-gradient(to right, #F784F5 0%, #82DFF6 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 45px;
}

.section-heading {
  font-size: 45px;
  font-weight: 500;
  text-align: center;
}

.mySkills {
  margin: 0px 150px 50px;
  padding-top: 130px;
}

.padding {
  padding: 80px 0px 70px;
  flex-wrap: wrap;
}

.skills {
  padding: 30px;
  margin: 35px;
  width: 120px;
  height: 120px;
  border: 2px solid #F784F5;
  box-shadow: 0 0 10px #F784F5;
  border-radius: 5px;
  transform: translate(0px,0px) rotate(45deg);
  transition: .5s all ease-in-out;
  background: rgba(35,29,54,0.8);
}

.skill-icon {
  width: 60%;
  transform: scale(1) rotate(-45deg);
  position: absolute;
  transition: 0.25s;
}

.skill-iconMore {
  transform: scale(1.2) rotate(-45deg);
  transition: 0.25s;
  width: 60%;
  position: absolute;
}

.skill-textShow {
  opacity: 1;
  visibility: visible;
  font-size: 20px;
  text-shadow: 2px 2px 4px black;
  transform: rotate(-45deg);
  position: absolute;
  margin-top: 80px;
  margin-left: 80px;
  transition: 0.25s;
}

.skill-text {
  font-size: 20px;
  text-shadow: 2px 2px 4px black;
  transform: rotate(-45deg);
  position: absolute;
  margin-top: 90px;
  margin-left: 80px;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s;
}

.skills:hover {
  background-color: rgba(247,132,245,0.5);
  transform: translate(-5px, -5px) rotate(45deg);
}

.rights-container {
  padding: 10px 7px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.rocket-icon {
  position: absolute;
  width: 90px;
  right: 50px;
  bottom: -100px;
  z-index: -5;
  animation: rocket 10s ease infinite;
}

.contact-link {
  padding: 100px 200px 35px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.or {
  font-size: 35px;
  font-weight: 500;
  font-style: italic;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.3);
}

.offer {
  font-size: 45px;
  font-weight: 700;
  margin: 15px 0px; 
  background: linear-gradient(to right, #F784F5 0%, #82DFF6 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.3);
}

.mySocial-container {
  margin: 50px 20px 0px 0px;
}

.my-social {
  font-size: 22px;
  transition: 0.3s all ease-in-out;
  color: #FFFFFF;
  border: 2px solid #82DFF6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.my-social:hover {
  transition: 0.3s all ease-in-out;
  transform: scale(1.1);
  color:#F784F5;
  background: rgba(35,29,54,0.9);
}

.scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 5;
}

.scroll-to-top button {
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s;
  width: 54px;
  height: 54px;
}

.scroll-to-top button:hover {
  border: 2px solid #F784F5;
  box-shadow: 0 0 4px #F784F5;
}

.topBtn {
  font-size: 50px;
  color: #82DFF6;
}

.myContacts, .myProjects, .otherProjects {
  padding: 110px 150px 50px;
  min-height: 100vh;
}

.otherProjects {
  padding: 110px 150px 0px;
}

.subheading {
  font-size: 22px;
  padding: 30px 0px 0px;
  color: #BDA5AD;
  text-align: center;
}

.contacts-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 100px 0px 0px;
}

form {
  background: linear-gradient(to left, rgba(247,132,245,0.7) 0%, rgba(130,223,246,0.7) 100%);
  border: none;
  border-radius: 15px;
  width: 50%;
}

.response-container {
  padding: 40px 20px;
  border-radius: 15px;
  background: linear-gradient(to left, rgba(247,132,245,0.7) 0%, rgba(130,223,246,0.7) 100%);
  width: 50%;
  height: 50vh;
  text-align: center;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 5px 5px 15px rgba(255,255,255,.2); 
box-shadow: 5px 5px 15px 5px rgba(255,255,255,.2);
}

.form-response {
  font-size: 35px;
  font-weight: 700;
  padding: 15px 0px;
  z-index: 5;
  color:#0F0823;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
}

.thanks-icon {
  position: absolute;
  bottom: 0;
  right: 5%;
  z-index: 2;
  animation: thanks 2.2s ease;
}

.form-cover {
  background: rgba(245, 229, 229, 0.20);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 35px 0px;
  transform: translate(-20px,-20px);
  text-align: center;
}

.form-block {
  width: 100%;
}

.input {
background: rgba(255, 255, 255, 0.26);
border-radius: 15px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 2px solid rgba(255, 255, 255, 0.3);
font-size: 18px;
font-family: 'Inconsolata', monospace;
padding: 10px 30px;
margin: 15px;
text-align: left;
width: 70%;
color: #0F0823;
cursor: pointer;
}

.input::placeholder {
color: #0F0823;
font-size: 18px;
}

.input:focus {
outline-color: #F784F5;
background-color: #FFFEFF;
}

.textarea {
height: 120px;
}

.send-btn {
  padding: 10px 50px;
  margin: 10px 0px 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  background-color: #FFFEFF;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: .5s;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #0F0823;
  z-index: 1;
  font-family: 'Inconsolata', monospace;
}

.send-btn::before, .send-btn::after {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: #0F0823;
  transition: 1s ease;
}

.send-btn::before {
  top: -1em;
  left: -1em;
}

.send-btn::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.send-btn:hover::before, .send-btn:hover::after {
  height: 410px;
  width: 410px;
}

.send-btn:hover {
  color: #ffffff;
}

.send-btn:active {
  filter: brightness(.8);
}

.contactMe-icon {
  font-size: 40px;
  margin-right: 20px;
  padding: 7px;
  background: linear-gradient(344deg, rgba(247,132,245,0.2) 0%, rgba(130,223,246,0.2) 100%);
  box-shadow: 0px 0px 4px #000000;
  border: none;
  border-radius: 5px;
  color: #F784F5;
}

.white {
  color: #FFFFFF;
  text-decoration: none;
}

.white:hover {
  color: #F784F5;
}

.more-space {
  padding: 25px 0px;
}

.sendIcon {
  position:  absolute;
  width: 150px;
  z-index: -5;
  top: -200px;
  right: 100px;
  animation: send 2s ease-in-out infinite;
  animation-direction: alternate-reverse;
}

.projects-subheading {
  font-size: 40px;
  font-weight: 500;
  margin: 70px 55px 35px 0px;
}

.filter-btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.filteredProjects-btn {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 3px 5px;
  width: 100px;
  height: 40px;
  background: linear-gradient(344deg, rgba(247,132,245,0.2) 0%, rgba(130,223,246,0.2) 100%);
  box-shadow: 0px 0px 4px black;
  color: #F784F5;
  text-shadow: 0px 0px 4px black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.projects-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 40px 0px 0px 0px;
}

.my-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  min-height: 600px;
  gap: 30px;
  margin: 20px;
  padding: 30px;
  border: 1px solid #0F0823;
  border-radius: 15px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.chosen {
  background: rgba(35,29,54,0.7);
  border: 1px solid #82DFF6;
}

.projectPhoto {
  margin: 20px 0px;
  filter: drop-shadow( 0 0 3px #82DFF6);
  width: 90%;
  transition: all 0.4s ease-in-out;
  align-self: center;
}

.shadow {
  filter: drop-shadow( 0 0 9px #82DFF6);
  transform: scale(1.15);
}

.projectName-link {
  text-decoration: none;
  color:#FFFEFF;
}

.project-name {
  font-size: 35px;
  text-shadow: 0px 0px 4px black;
  margin-bottom: 0;
  text-align: center;
}

.blue {
  color:#82DFF6;
}

.arrow, .projectArrow {
  font-size: 25px;
  transform: translate(0px, -5px);
  transition: all 0.4s ease-in-out;
}

.projectArrow {
  font-size: 18px;
}

.straight-arrow {
  font-size: 25px;
  transition: all 0.4s ease-in-out;
}

.forward {
  color:#82DFF6;
  transform: translateX(10px);
}

.up {
  transform: translate(-7px, 5px);
}

.moveUp {
  transform: translate(-5px, 3px);
}

.project-description {
  color:#BDA5AD;
  font-size: 18px;
  line-height: 1.2;
  text-shadow: 0px 0px 4px black;
}

.skills-btn {
  background: linear-gradient(344deg, rgba(247,132,245,0.2) 0%, rgba(130,223,246,0.2) 100%);
  box-shadow: 0px 0px 4px black;
  text-shadow: 0px 0px 4px black;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color:#F784F5;
}

.infinity-icon {
  width: 55px;
  margin-right: 10px;
  animation: infinity 3s ease-in-out infinite;
}

.otherProjects-background {
  background-image: url("../src/assets/parallax.jpg");
  height: 300px;
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  margin: 0;
}

.otherProjects-cover {
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  width: 100%;
  height: 100%;
}

.glass-bg {
  padding: 30px 50px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(35,29,54,0.9);
  width: 50%;
  text-align: center;
}

.otherProjects-link {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 1px;
}

.arrow-container {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.table-container {
  margin: 70px 0px 0px;
}

table {
  width: 100%;
}

.table-head, .table-data {
  text-align: left;
  min-height: 70px;
  border-bottom: 1px solid rgba(130,223,246,0.3);
  font-size: 22px;
}

.table-head {
  color:#82DFF6;
  font-size: 21px;
  padding-bottom: 10px;
}

.filterProjects-btnGroup {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;
  padding: 10px 0px;
}

@keyframes infinity {
  0% {
    transform: rotate(0deg);
}

  100% {
    transform: rotate(360deg);
}
}

@keyframes homeIcon {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(30px);
  }
}

@keyframes rocket {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(-1000px);
  }
}

@keyframes send {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(50px);
  }
}

@keyframes thanks {
  0% {
    transform: translateY(150px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media all and (max-width: 1350px) {
  .intoduction-words {
    padding: 20px;
    width: 40%;
  }

  .about {
    margin: 0px 150px 50px;
  }

  .contact-link {
    padding: 100px 150px 35px;
  }
}

@media all and (max-width: 1200px) {
  .row {
    gap: 50px;
    padding-top: 150px;
  }
  
  .my-photo {
    width: 300px;
    height: 470px;
  }

  .aboutMe-heading {
    font-size: 40px;
    margin-bottom: 30px;
  }
  
  .myName {
    font-size: 40px;
  }

  .my-info, .my-facts {
    font-size: 20px;
    padding: 7px 0px;
  }

  .section-heading, .offer {
    font-size: 40px;
  }

  .or {
    font-size: 30px;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 60px;
  }

  .middle {
    align-self: center;
  }

  .mySocial-container {
    margin: 70px 10px 0px 10px;
  }

  .contacts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  form, .response-container {
    width: 95%;
  }

  .more-space {
    padding: 25px 0px;
  }

  .sendIcon {
    width: 130px;
    top: 80px;
    right: 0px;
  }

  .projects-subheading {
    font-size: 35px;
  }

  .my-project {
    width: 100%;
    margin: 30px 0px;
  }

  .projectPhoto {
    width: 70%;
  }

  .rocket-icon {
    width: 85px;
  }

  @keyframes rocket {
    0% {
      transform: translateY(0px);
    }
  
    100% {
      transform: translateY(-1400px);
    }
  }
}

@media all and (max-width: 1100px) {
  .about, .mySkills {
    margin: 0px 100px 20px;
  }

  .myContacts, .myProjects {
    padding: 110px 100px 30px;
  }

  .otherProjects {
    padding: 110px 100px 0px;
  }

  .contact-link {
    padding: 90px 100px 35px;
  }

  .code {
    top: 20%;
    left: 10%;
  }
  
  .cloud {
    top: 40%;
    right: 20%;
  }
  
  .network {
    bottom: 20%;
    left: 30%;
  }
}

@media all and (max-width: 1030px) {
  .main-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .intoduction-words {
    align-items: center;
    width: 90%;
    background: rgba(35,29,54,0.9);
    margin: 0% 0% 8% 0%;
  }

  .first {
    font-size: 28px;
  }
  
  .second {
    font-size: 33px;
    margin: 15px 0px 13px 0px;
  }
  
  .third {
    font-size: 28px;
    margin-left: 0px;
  }
  
  .forth {
    font-size: 18px;
    text-align: center;
    margin: 30px 0px;
  }
  
  .btn-group {
    justify-content: center;
    gap: 30px;
  }
  
  .cta {
    font-size: 16px;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    padding-top: 100px;
  }

  .aboutMe-heading, .margin-top {
    text-align: center;
  }

  .mySkills {
    padding-top: 100px;
  }

  .glass-bg {
    width: 80%;
  }
}

@media all and (max-width: 850px) {
  .about, .mySkills {
    margin: 0px 50px 20px;
  }

  .myContacts, .myProjects {
    padding: 110px 50px 50px;
  }

  .otherProjects {
    padding: 110px 50px 0px;
  }

  .contact-link {
    padding: 90px 50px 30px;
  }

  .rocket-icon {
    position: absolute;
    width: 70px;
    right: 10px;
  }

  .my-social {
    font-size: 20px;
  }

  .my-project {
    margin: 20px 0px;
    min-height: 300px;
  }
}

@media all and (max-width: 540px) {
  .form-response {
    font-size: 30px;
    padding: 10px 0px;
  }

.response-container {
  width: 100%;
}

form {
  width: 100%;
  margin-left: 10px;
}

.form-cover {
  padding: 30px 5px;
  transform: translate(-10px,-10px);
}

.contacts-container {
  margin: 65px 0px 0px;
}

.subheading {
  font-size: 21px;
  padding: 25px 0px 0px;
}

.input {
  padding: 10px 20px;
  width: 95%;
}

.sendIcon {
  width: 100px;
  top: 70px;
  right: 30px;
}

.flexWrap {
  flex-wrap: wrap;
}

.glass-bg {
  width: 80%;
  padding: 20px 0px;
}
}

@media all and (max-width: 520px) {
  .intoduction-words {
    margin: 0% 0% 25% 0%;
  }
  
  .frontIcon {
    width: 60px;
  }

  .first {
    font-size: 25px;
  }
  
  .second {
    font-size: 27px;
    margin: 12px 0px 8px 0px;
  }
  
  .third {
    font-size: 25px;
  }
  
  .forth {
    font-size: 17px;
    text-align: center;
    margin: 25px 0px;
  }
  
  .btn-group {
    gap: 20px;
  }
  
  .cta {
    padding: 10px 15px;
  }

  .about, .mySkills {
    margin: 0px 30px 20px;
  }

  .myContacts, .myProjects {
    padding: 100px 30px 50px;
  }

  .otherProjects {
    padding: 100px 30px 0px;
  }

  .contact-link {
    padding: 70px 30px 30px;
  }

  .padding {
    padding: 70px 0px 45px;
  }

  .skills {
    margin: 30px;
    width: 100px;
    height: 100px;
  }
  
  .skill-text {
    margin-top: 80px;
    margin-left: 70px;
  }

  .skill-textShow {
    margin-top: 70px;
    margin-left: 70px;
  }

  .rocket-icon {
    width: 60px;
    right: 0px;
  }

  .cloud {
    top: 40%;
    right: 10%;
  }

  .my-info {
    font-size: 16px;
    padding: 5px 0px;
  }

  .more-space {
    padding: 25px 0px;
  }

  .contactMe-icon {
    font-size: 35px;
    margin-right: 10px;
    padding: 5px;
  }

  .my-project {
    padding: 30px 15px;
  }

  .projectPhoto {
    width: 90%;
    margin: 20px 0px 10px;
  }

  .project-name {
    font-size: 30px;
  }

  .arrow, .straight-arrow {
    font-size: 20px;
  }
  
  .project-description {
    font-size: 16px;
  }

  .filteredProjects-btn {
    font-size: 16px;
    height: 35px;
  }

  .projects-subheading {
    margin: 70px 0px 35px 0px;
  }

  .glass-bg {
    width: 90%;
  }

  .otherProjects-link {
    font-size: 27px;
    letter-spacing: 0;
  }

  .table-data {
    font-size: 21px;
  }
  
  .table-head {
    font-size: 20px;
  }

  .filterProjects-btnGroup {
    font-size: 16px;
  }

  .projectArrow {
    font-size: 16px;
  }
}

@media all and (max-width:380px) {
  .about, .mySkills {
    margin: 0px 20px 20px;
  }

  .myContacts, .myProjects {
    padding: 100px 20px 50px;
  }

  .otherProjects {
    padding: 100px 20px 0px;
  }

  .contact-link {
    padding: 70px 20px 30px;
  }

  .rocket-icon {
    width: 55px;
    right: -10px;
  }

  .my-social {
    padding: 8px;
  }

  .scroll-to-top {
    bottom: 50px;
  }

  .scroll-to-top button {
    width: 50px;
    height: 50px;
  }

  .topBtn {
    font-size: 45px;
  }

  .form-response {
    font-size: 25px;
  }

  .project-name {
    font-size: 27px;
  }

  .infinity-icon {
    width: 50px;
    margin-right: 10px;
    animation: infinity 3s ease-in-out infinite;
  }

  .offer {
    font-size: 35px;
  }

  .or {
    font-size: 23px;
  }

  .my-photo {
    width: 280px;
    height: 450px;
  }
}

@media all and (max-width:350px) {
  .skills {
    margin: 25px;
    width: 90px;
    height: 90px;
  }
  
  .skill-text {
    margin-top: 70px;
    margin-left: 70px;
  }

  .aboutMe-heading {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .cta {
    font-size: 15px;
    padding: 8px 15px;
    border: 1px solid #FFFFFF;
  }

  .first {
    font-size: 22px;
  }
  
  .second {
    font-size: 25px;
    margin: 12px 0px 8px 0px;
    text-align: center;
  }
  
  .third {
    font-size: 22px;
  }
  
  .forth {
    font-size: 16px;
    text-align: center;
    margin: 25px 0px;
  }
}


