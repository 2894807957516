.certs-background {
    background-image: url("../../assets/parallax.jpg");
    height: 710px;
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    margin: 0;
}

.certs-cover {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
    width: 100%;
    height: 100%;
}

.carousel-container {
    display: block;
    position: relative;
    width: 700px;
    height: 510px;
    background-color: transparent;
    margin: 0px auto;
    padding: 0;
}

.swiper {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    cursor: grab;
}

.swiper-slide {
    background-color: #0F0823;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
}

@media all and (max-width: 800px) {
    .carousel-container {
        width: 90%;
        height: 490px;
    }

    .certs-background {
        height: 670px;
    }
}

@media all and (max-width: 700px) {
    .carousel-container {
        height: 420px;
    }

    .certs-background {
        height: 610px;
    }
}

@media all and (max-width: 600px) {
    .carousel-container {
        height: 380px;
    }

    .certs-background {
        height: 550px;
    }
}

@media all and (max-width: 540px) {
    .carousel-container {
        height: 320px;
    }

    .certs-background {
        height: 510px;
    }
}

@media all and (max-width: 430px) {
    .carousel-container {
        height: 240px;
    }

    .certs-background {
        height: 370px;
    }
}

@media all and (max-width:380px) {
    .carousel-container {
        height: 230px;
    }

    .certs-background {
        height: 340px;
    }
}