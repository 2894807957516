.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    padding: 0 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(35,29,54,0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 50;
}

.nav-list {
    display: flex;
    gap: 50px;
    list-style-type: none;
}

ul > li:hover > .nav-link {
    color: #F784F5;
}

ul > li:hover > .nav-link::before {
    visibility: visible;
    scale: 1 1;
}

.nav-link {
    text-decoration: none;
    color: #FFFFFF;
    position: relative;
    padding: 0px 0px 3px 0px;
    font-size: 20px;
    text-shadow: 1px 2px 10px #000;
}

.nav-link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5px;
    bottom: 0px;
    left: 0px;
    background: #F784F5;
    transition: all 0.3s ease-in-out;
    scale: 0 0;
    visibility: hidden;
}

.my-name {
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 2px;
    background: linear-gradient(to right, #F784F5 0%, #82DFF6 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
    text-decoration: none;
}

.menu-toggle {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 90;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hamburger {
    scale: 1.3;
    height: 34px;
    margin: 25px 25px 0px 0px;
    padding: 0px 2px 5px 2px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 5px #000;
    border-radius: 5px;
    background: rgba( 255, 255, 255, 0.05 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
}

.hamburger svg {
    height: 34px;
    transform: translate(-1px, -1px);
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.hamburger .line {
    fill: none;
    stroke: #F784F5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.75;
    transition: stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
    filter: drop-shadow(0px 0px 5px #000);
}

.hamburger .line-top-bottom {
    stroke-dasharray: 12 63;
}

.open .hamburger svg {
    transform: rotate(-45deg) translate(0);
}

.open .hamburger svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
}

@media all and (max-width: 1100px) {
    .navbar {
        padding: 0 100px;
    }
}

@media all and (max-width: 850px) {
    .navbar {
        padding: 0 50px;
    }
}

@media all and (max-width: 580px) {
    .menu-toggle {
        visibility: visible;
    }

    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        padding: 150px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: transparent;
        box-shadow: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        z-index: 50;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
    }

    .navbar.open {
        opacity: 1;
        visibility: visible;
    }

    .nav-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 70px;
    }

    .nav-link {
        font-size: 23px;
    }

    .background {
        position: fixed;
        z-index: 20;
        top: 0px;
        right: 0px;
        aspect-ratio: 1 / 1;
        translate: 50% -50%;
        width: 40px;
        background: #0F0823;
        border-radius: 50%;
        opacity: 0;
        transition: 0.6s;
    }

    .background.open {
        width: 2000px;
        opacity: 0.9;
    }

    .appear {
        animation: appear 0.7s backwards;
    }

    @keyframes appear {
        0% {
            opacity: 0;
            translate: 0 30px;
        }
        100% {
            opacity: 1;
        }
    }
}

@media all and (max-width: 380px) {
    .hamburger, .hamburger svg {
        height: 32px;
    }
}
